@import "~@/erp/styles/variables/variables.scss";
















































































































































































































































































































































































































.split-products-shipment{
  .split-products-shipment-row{
    margin: 0;
    .el-col{
      margin-bottom: 20px;
    }
  }
  .add-btn{
    padding: 10px;
    text-align: center;
    span{
      color: #1890FF;
      text-decoration: underline;
      cursor: pointer;
    }
  }
::v-deep {
  .el-form-item{
      margin: 14px 0;
      .el-date-editor{
        width: 100%;
      }
  }
}
}
