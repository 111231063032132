@import "~@/erp/styles/variables/variables.scss";






































































































































































































































































































































































































































.custom-wrap {
  .base-info {
    height: calc(100% - 44px);
    overflow-y: auto;
  }

  .tag-wrap {
    position: relative;
    display: flex;
    justify-content: center;

    .el-tag {
      position: absolute;
      top: -10px;
      right: 0;
      z-index: 2;
    }

    .el-image {
      width: 50px;
      height: 50px;
      border-radius: 2px;
    }
  }
}

.title {
  font-weight: bold;
  font-size: 14px;
  font-family: 'PingFang-Bold';
  margin-right: 10px;
}
